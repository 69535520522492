"use client";

import React, { useRef } from "react";
import ContentContainer from "@/app/[locale]/(landingPage)/(homepageLayout)/home/components/ContentContainer";
import { Card, CardBody } from "@nextui-org/react";
import { StarFilled } from "@ant-design/icons";
import image6 from "./image (6).png";
import image7 from "./image (7).png";
import image18 from "./image 18.png";
import Image from "next/image";
import { useInView } from "framer-motion";
import dynamic from "next/dynamic";
const VideoScreenshot = dynamic(() => import("../VideoScreenshot"));

interface Testimonial {
  testimony: string;
  description: string;
  author: string;
  avatar: any;
  authorTitle: string;
}

interface Testimonials {
  ucall: Testimonial[];
  adahub: Testimonial[];
}

const testimonials: Testimonials = {
  ucall: [
    {
      testimony: "“Gọi 1000 data chỉ mất 100-200 nghìn”",
      description:
        "Giải pháp marketing auto call AI Callbot Gọi tự động của UCALL giúp công ty tôi tiết kiệm rất nhiều thời gian và công sức gọi bán hàng qua điện thoại, gọi hết 1000 data khách hàng chỉ mất 100-200 nghìn. Chất lượng dịch vụ và chăm sóc khách hàng tốt, bạn nhân viên hỗ trợ rất nhiệt tình.",
      author: "Anh Hùng Lâm",
      avatar: image18,
      authorTitle: "CEO Công ty Hùng Lâm Land",
    },
    {
      testimony: "“Telesale luôn đạt và vượt doanh số”",
      description:
        "Edumet sử dụng tool gọi điện auto call AI Callbot này của UCALL từ những ngày đầu tiên, đến bây giờ việc tìm kiếm data khách hàng và chăm sóc học viên đều được hỗ trợ tự động hết. Nhân viên telesale luôn đạt và vượt doanh số, bớt khâu quay số thủ công. Các bạn học viên luôn được thông báo, nhắc lịch học, đóng học phí kịp thời.",
      author: "Anh Nguyễn Như Tuấn Mạnh",
      avatar: image6,
      authorTitle: "Founder/CEO Edumet và Strive Edu",
    },
    {
      testimony: "“1 giải pháp marketing cho bất động sản”",
      description:
        "1 giải pháp marketing đặc lực cho doanh nghiệp bất động sản. Tôi dùng giải pháp auto call AI Callbot của UCALL để giới thiệu dự án chung cư đợt nền thấy tiết kiệm chi phí thuê telesales đáng kể và hiệu quả mang lại tốt hơn rất nhiều. Tôi có thể bốc tách nhu cầu khách hàng và nghe lại ghi âm, chọn khung giờ vàng telesales và gọi cả trăm cuộc gọi cùng một lúc.",
      author: "Anh Mai Khánh Trung",
      avatar: image7,
      authorTitle: "CEO Công ty cổ phần CEN HCM",
    },
  ],
  adahub: [
    {
      testimony: "“Quản lý khách hàng hiệu quả hơn”",
      description:
        "Trước khi dùng Adahub thì chúng tôi chỉ dùng Excel để quản lý thông tin khách hàng. Tuy nhiên khi số lượng khách hàng hàng tích lũy lên hơn 2000 khách hàng, chúng tôi cần sự giúp đỡ của công nghệ và phần mềm. Adahub đã giúp chúng tôi quản lý khách hàng khoa học và hiệu quả hơn.",
      author: "Anh Hùng Lâm",
      avatar: image18,
      authorTitle: "CEO Công ty Hùng Lâm Land",
    },
    {
      testimony: "“Tỷ lệ chốt deal tăng gấp đôi”",
      description:
        "Dùng Adahub rất tiện, đầy đủ từ tìm kiếm khách hàng, lọc khách hàng tiềm năng, quản lý khách hàng trong và sau bán. Tổng đài telesale là chức năng bên anh dùng nhiều nhất. Sau khi gọi xong có thể sử dụng CRM và chat đa kênh để chăm sóc khách hàng tiếp rất là tiện. Nên tỷ lệ chốt deal cũng phải tăng gấp đôi.",
      author: "Anh Nguyễn Như Tuấn Mạnh",
      avatar: image6,
      authorTitle: "Founder/CEO Edumet và Strive Edu",
    },
    {
      testimony: "“Đội ngũ support  rất tuyệt vời”",
      description:
        "Đội ngũ support của Adahub rất tuyệt vời. Bên anh dùng gần hết các tính năng của Adahub như quản lý khách hàng, cơ hội bán hàng, callbot, tổng đài gọi điện. Các bạn hỗ trợ viên hướng dẫn bên anh dùng rất chi tiết. Phần mềm cũng rất dễ dùng nên nhân viên chỉ cần hướng dẫn một lần là biết dùng.",
      author: "Anh Mai Khánh Trung",
      avatar: image7,
      authorTitle: "CEO Công ty cổ phần CEN HCM",
    },
  ],
};

const Testimonial = ({ type = "ucall" }: { type?: "ucall" | "adahub" }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, {
    once: true,
  });

  return (
    <section id="testimonial" ref={ref}>
      <div className="full-size flex flex-column items-center justify-center mt-9 mb-3">
        <ContentContainer>
          <div>
            <div className="text-center">
              {type == "ucall" && (
                <>
                  <h3 className="font-semibold text-3xl text-adahubSecondary">
                    Cùng lắng nghe vì sao các doanh nghiệp tin dùng UCALL để
                    giải phóng tiềm năng đội ngũ kinh doanh của mình
                  </h3>
                </>
              )}
              {type == "adahub" && (
                <>
                  <h3 className="font-semibold text-3xl text-adahubSecondary">
                    Cùng lắng nghe vì sao các doanh nghiệp tin dùng Adahub để
                    giải phóng tiềm năng đội ngũ kinh doanh của mình
                  </h3>
                </>
              )}
            </div>
            <div className="mt-16">
              <div className="row gx-4 gy-4">
                {testimonials[type].map((props) => {
                  const {
                    authorTitle,
                    author,
                    testimony,
                    description,
                    avatar,
                  } = props;
                  return (
                    <div
                      key={`testimony_${JSON.stringify(props)}`}
                      className="col-12 col-md-4">
                      <Card className="h-[360px] md:h-[390px]" isHoverable>
                        <CardBody>
                          {/* eslint-disable-next-line react/no-unescaped-entities */}
                          <div className="flex flex-column justify-content-between h-full">
                            <div>
                              <div className="font-semibold text-sm">
                                {testimony}
                              </div>
                              <div className="flex flex-row mt-3">
                                <StarFilled className="text-yellow-300 text-lg" />
                                <StarFilled className="text-yellow-300 text-lg" />
                                <StarFilled className="text-yellow-300 text-lg" />
                                <StarFilled className="text-yellow-300 text-lg" />
                                <StarFilled className="text-yellow-300 text-lg" />
                              </div>
                              <p className="mt-6 text-sm text-justify">
                                {description}
                              </p>
                            </div>
                            <div className="flex flex-row items-center gap-x-4 mt-2 md:mt-7">
                              <div>
                                <Image
                                  placeholder="blur"
                                  src={avatar}
                                  alt="avatar"
                                />
                              </div>
                              <div className="flex flex-column">
                                <div className="text-sm">{author}</div>
                                <div className="text-xs">{authorTitle}</div>
                              </div>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </ContentContainer>

        {/*Only display youtube embed when in view, BECAUSE IT SUPER HEAVY*/}
        <div className="mt-10 ">{isInView && <VideoScreenshot />}</div>
      </div>
    </section>
  );
};

export default Testimonial;
