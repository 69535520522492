"use client";

import ContentContainer from "@/app/[locale]/(landingPage)/(homepageLayout)/home/components/ContentContainer";
import HeroArenaGif from "./Homepage Hero Area.gif";
import Image from "next/image";
import FadeIn from "@/components/shared/FadeIn";
import { Button } from "@nextui-org/react";

const HeroHomepage = () => {
  return (
    <ContentContainer>
      <div className="mt-16 row">
        <div className="col-12 col-md-6">
          <h1 className="font-bold text-4xl text-adahubSecondary">
            Giải pháp Tổng đài CRM
            <br /> Tích hợp AI số 1
          </h1>
          <FadeIn className="mt-9 flex flex-column gap-y-3 max-w-[410px]">
            <div className="flex flex-row items-center">
              Giúp doanh nghiệp tối ưu vận hành, gia tăng lợi thế cạnh tranh,
              tập trung tăng trưởng kinh doanh.
            </div>
          </FadeIn>
          <div className="flex gap-3">
            <Button
              color="primary"
              onClick={() => {
                open();
              }}
              className="mt-4 mb-4 bg-adahubSecondary rounded-3xl transform transition-transform hover:scale-105"
              variant="solid">
              Tư vấn chuyên sâu
            </Button>
            <Button
              color="primary"
              variant="bordered"
              onClick={() => {
                open();
              }}
              className="mt-4 mb-4 border-adahubSecondary text-adahubSecondary bg-white rounded-3xl transform transition-transform hover:scale-105">
              Dùng thử miễn phí
            </Button>
          </div>
        </div>
        <div className="col-12 col-md-6 p-0">
          <FadeIn
            delay={700}
            transitionDuration={700}
            className="transition-opacity ease-in duration-2400">
            <Image
              // placeholder="blur"
              src={HeroArenaGif}
              alt="callbot product image"
            />
          </FadeIn>
        </div>
      </div>
    </ContentContainer>
  );
};

export default HeroHomepage;
