import {
  defaultShouldDehydrateQuery,
  QueryClient,
} from "@tanstack/react-query";
import SuperJSON from "superjson";

export const defaultConfig = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
      staleTime: 300,
    },
  },
};

export const queryClient = new QueryClient(defaultConfig);

export const createQueryClient = () => {
  return new QueryClient(defaultConfig);
};
