"use client";
import ContentContainer from "@/app/[locale]/(landingPage)/(homepageLayout)/home/components/ContentContainer";
import { Card, CardBody, CardHeader } from "@nextui-org/react";
import Image from "next/image";
import Link from "next/link";
import Group from "./Group.png";
import Image1 from "./Thumnails YT.png";
import Image2 from "./Thumnails YT (1).png";
import Image3 from "./Thumnails YT (2).png";
import { ArrowRightOutlined } from "@ant-design/icons";

const BlogPosts = () => {
  const cardData = [
    {
      id: 1,
      blogImage: Image2,
      groupImage: Group,
      date: "11/11/2024",
      title: "Lead Management Quản lý khách hàng tiềm năng",
      link: "https://blog.adahub.vn/lead-management/",
      content:
        "Là một chủ doanh nghiệp, bạn biết rằng việc tạo ra khách hàng tiềm năng là rất quan trọng để …",
    },
    {
      id: 2,
      blogImage: Image3,
      groupImage: Group,
      date: "08/11/2024",
      title: "6 Phương pháp nuôi dưỡng khách hàng tiềm năng hiệu quả",
      link: "https://blog.adahub.vn/nuoi-duong-khach-hang/",
      content:
        "Một nhà tiếp thị thông minh sẽ hiểu được tầm quan trọng của việc tương tác và nuôi dưỡng khách …",
    },
    {
      id: 3,
      blogImage: Image1,
      groupImage: Group,
      date: "06/11/2024",
      title: "Phần mềm Telesale là gì? 5 kiểu quay số của Telesale Call Center",
      link: "https://blog.adahub.vn/phan-mem-telesale/",
      content:
        "Trong kỷ nguyên số hóa hiện nay, việc tiếp cận và chăm sóc khách hàng một cách hiệu quả là…",
    },
  ];
  return (
    <ContentContainer>
      <div className="w-full my-10">
        <div className="md:flex md:justify-between mb-5">
          <h2 className="text-3xl text-adahubSecondary font-bold">
            Các bài blog mới nhất
          </h2>
          <Link className="text-[#01A9DB]" href={"https://blog.adahub.vn/"}>
            Tất cả bài viết
          </Link>
        </div>
        <div className="overflow-x-auto whitespace-no-wrap">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
            {cardData.map((card) => (
              <Card key={card.id} className="pb-2">
                <div className="flex justify-center">
                  <Image
                    alt="Card background"
                    className="object-cover rounded-xl"
                    src={card.blogImage}
                    width={318}
                    height={150}
                  />
                </div>
                <CardHeader className="pb-0 pt-2 flex items-start gap-2 mt-2">
                  <Image src={card.groupImage} alt="Group" />
                  <p className="text-[14px]">{card.date}</p>
                </CardHeader>
                <CardBody className="flex-col items-start pt-2 gap-2">
                  <h3 className="text-adahubSecondary">{card.title}</h3>
                  <p className="text-[14px]">{card.content}</p>
                  <Link
                    href={card.link}
                    className="flex gap-2 text-[#2D68F8] text-[14px] items-center cursor-pointer">
                    <p>Xem thêm</p>
                    <ArrowRightOutlined className="mt-1" />
                  </Link>
                </CardBody>
              </Card>
            ))}
          </div>
        </div>
      </div>
    </ContentContainer>
  );
};

export default BlogPosts;
