"use client";

import { Button, Input, Select, SelectItem } from "@nextui-org/react";
import { SubmitHandler, useForm } from "react-hook-form";
import useCreateLead from "@/services/landing_page/useCreateLead";
import { uuidv4 } from "@firebase/util";
import { usePathname, useRouter } from "next/navigation";
import LandingPageRoutes from "@/config/landingPage/routes";
import { logSubmitLeadForm } from "@/utils/landingPage/logEvent";

export const industries = [
  { key: "crm_ban_hang", label: "CRM Bán hàng" },
  { key: "chat_da_kenh", label: "Chat đa kênh" },
  { key: "tong_dai_ao", label: "Tổng đài ảo" },
  { key: "callbot_ai_ucall", label: "Callbot AI Ucall" },
  { key: "chien_dich_tin_nhan", label: "Chiến dịch tin nhắn" },
  { key: "chien_dich_telesales", label: "Chiến dịch Telesales" },
  { key: "nen_tang_data_adalead", label: "Nền tảng data AdaLead" },
  { key: "tu_dong_hoa_adaflow", label: "Tự động hóa AdaFlow" },
  { key: "email_marketing", label: "Email Marketing" },
];

const inputProps = {
  variant: "bordered" as const,
  classNames: {
    inputWrapper: "bg-white shadow-none",
  },
  labelPlacement: "outside" as const,
  isClearable: true,
};

export type Inputs = {
  name: string;
  phone_number: string;
  product: string;
  email: string;
  notes: string;
};

const ContactForm = ({ check_mobile = true }: { check_mobile?: boolean }) => {
  const { push } = useRouter();
  const isUcall = usePathname()?.includes("/callbot");
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<Inputs>();
  const { mutateAsync: createLead, isPending: isCreatingLead } =
    useCreateLead("FORM");
  const onSubmit: SubmitHandler<Inputs> = async (data) => {  

    let email = data?.email;
    const product = data.product == [] ? "" : data.product
    if (!email) {
      email = `KHONG_DIEN_MAIL_${uuidv4()}@abc.com`;
    }
    await createLead({ ...data, email, product });
    logSubmitLeadForm({
      email: email,
      phone_number: data.phone_number,
    });
    push(`${LandingPageRoutes.thankYou}${isUcall ? "?logo=ucall" : ""}`);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="">
      <div className="flex flex-col gap-6">
        <Input
          key={"name"}
          type="text"
          label="Họ và tên"
          className={`${check_mobile && "hidden sm:block"}`}
          placeholder="Nhập họ và tên của bạn"
          {...inputProps}
          {...register("name")}
        />
        <Input
          key={"phone_number"}
          type="phone_number"
          isRequired
          label="Số điện thoại"
          placeholder="Nhập số điện thoại của bạn"
          {...inputProps}
          {...register("phone_number", { required: true })}
          {...(errors.phone_number
            ? {
                isInvalid: true,
                errorMessage: "Vui lòng nhập số điện thoại",
              }
            : {})}
        />
        <Input
          key={"email"}
          type="email"
          label="Email"
          className={`${check_mobile && "hidden sm:block"}`}
          placeholder="Nhập địa chỉ email của bạn"
          {...inputProps}
          {...register("email")}
        />

        <Select
          label="Bạn quan tâm đến sản phẩm nào?"
          placeholder="Lựa chọn sản phẩm bạn quan tâm"
          className={`${check_mobile && "hidden sm:block"}`}
          selectionMode="multiple"
          labelPlacement="outside"
          scrollShadowProps={{
            isEnabled: false,
          }}
          {...inputProps}
          classNames={{
            mainWrapper: "bg-white shadow-none rounded-2xl",
            trigger: "shadow-none",
          }}
          {...register("product")}>
          {industries.map((e) => (
            <SelectItem key={e.key}>{e.label}</SelectItem>
          ))}
        </Select>

        <Input
          key={"notes"}
          type="text"
          className={`${check_mobile && "hidden sm:block"}`}
          label="Ghi chú"
          placeholder="Nhập nội dung ghi chú"
          {...inputProps}
          {...register("notes")}
        />
      </div>
      <div className="flex flex-row items-center justify-center">
        <Button
          type="submit"
          isLoading={isCreatingLead}
          className="mt-10 bg-adahubSecondary text-base w-40 rounded-3xl h-14"
          color="primary">
          Gửi thông tin
        </Button>
      </div>
    </form>
  );
};

export default ContactForm;
